import CampoTexto from '../../components/Formulario/CampoTexto';
import Grid, { GridItem } from '../../components/layout/Grid';
import axios from 'axios';
import { useQuery } from 'react-query';
import CampoSeletor from '../../components/Formulario/CampoSeletor';
import CampoSenha from '../../components/Formulario/CampoSenha';
import CampoMultiSeletor from '@/components/Formulario/CampoMultiSeletor';

export const FormUsuario = ({
  value,
  onChange,
  fieldErrors,
}: {
  value: any;
  onChange: any;
  fieldErrors: any;
}) => {
  const newObj = !value?.id || value?.id < 0;

  const gruposQuery = useQuery(['grupos'], async () => {
    const res = await axios.get('/api/usuario_grupo', {
      withCredentials: true,
    });

    return res.data.result;
  });

  const gruposOptions =
    gruposQuery.data?.map((g: any) => ({ id: g.id, label: g.nome })) ?? [];

  const idsGrupos = value?.grupos?.map((g: any) => g.id) ?? [];

  const papeisOptions = [
    { id: 'USUARIO', label: 'Atendentes' },
    { id: 'GESTOR', label: 'Gestores' },
    { id: 'ADMIN', label: 'Administradores' },
  ];
  return (
    <Grid>
      <div style={{ position: 'absolute', height: 0 }}>
        <input
          type="text"
          name="username"
          style={{
            height: 0,
            width: 0,
            border: 'none',
            outline: 'none',
            margin: 0,
            padding: 0,
          }}
        />
        <input
          type="password"
          name="password"
          style={{
            height: 0,
            width: 0,
            border: 'none',
            outline: 'none',
            margin: 0,
            padding: 0,
          }}
        />
      </div>
      <GridItem sm={12} md={6}>
        <CampoTexto
          value={value?.nome ?? ''}
          onChange={v => onChange({ ...value, nome: v })}
          label="Nome"
          error={fieldErrors?.nome}
        />
      </GridItem>
      <GridItem sm={12} md={6}>
        <CampoSeletor
          noSearch
          value={value?.papel}
          onChange={v => onChange({ ...value, papel: v })}
          label="Papel"
          options={papeisOptions ?? []}
          error={fieldErrors?.papel}
          tooltipText={
            <div>
              <div>O papel define as permissões do usuário no sistema.</div>
              <div>
                Atendentes: Tem acesso apenas aos chats (restritos aos abertos
                para o atendente) e perfil
              </div>
              <div>
                Gestores: Tem acesso apenas aos chats, perfil e relatórios
              </div>
              <div>Administradores: Acesso total ao sistema</div>
            </div>
          }
          tooltipPosition="left"
        />
      </GridItem>
      <GridItem sm={12} md={6}>
        <CampoTexto
          value={value?.email}
          onChange={v => onChange({ ...value, email: v })}
          label="Usuário"
          error={fieldErrors?.email}
        />
      </GridItem>
      <GridItem sm={12} md={6}>
        <CampoSenha
          showStrength={!value?.id || value.senha?.length > 0}
          value={value?.senha}
          onChange={v => onChange({ ...value, senha: v })}
          label="Senha"
          error={fieldErrors?.senha}
          placeholder={value?.id ? '(inalterada)' : ''}
        />
      </GridItem>
      <GridItem sm={12}>
        <CampoMultiSeletor
          value={idsGrupos}
          onChange={v => onChange({ ...value, grupos: gruposOptions.filter((it: any) => v.includes(it.id)) })}
          label="Grupos / Departamentos"
          options={gruposOptions ?? []}
          error={fieldErrors?.grupos}
        />
      </GridItem>
    </Grid>
  );
};
