import Grid, { GridItem } from '../../components/layout/Grid';
import CampoNumero from '@/components/Formulario/CampoNumero';
import CampoDinheiro from '@/components/Formulario/CampoDinheiro';
import CampoMemo from '@/components/Formulario/CampoMemo';
import { CampoSeletorEstadoVenda } from './CampoSeletorEstadoVenda';
import { CampoSeletorCliente } from './CampoSeletorCliente';
import { CampoSeletorUsuario } from './CampoSeletorUsuario';
import { CampoSeletorProduto } from './CampoSeletorProduto';
import { useQuery } from 'react-query';
import axios from 'axios';
import { CampoPersonalizacao } from '@/components/Formulario/CampoPersonalizacao';
import CampoData from '@/components/Formulario/CampoData';
import { format, parse } from 'date-fns';

export const FormVenda = ({
  value,
  onChange,
  fieldErrors,
}: {
  value: any;
  onChange: any;
  fieldErrors: any;
}) => {
  const qCustomize = useQuery('venda/meta', async () => {
    const res = await axios.get('/api/venda/meta', {
      withCredentials: true,
    });

    return res.data.result;
  });

  return (
    <Grid>
      <div style={{ position: 'absolute', height: 0 }}>
        <input
          type="text"
          name="username"
          style={{
            height: 0,
            width: 0,
            border: 'none',
            outline: 'none',
            margin: 0,
            padding: 0,
          }}
        />
        <input
          type="password"
          name="password"
          style={{
            height: 0,
            width: 0,
            border: 'none',
            outline: 'none',
            margin: 0,
            padding: 0,
          }}
        />
      </div>

      <GridItem sm={12} md={6}>
        <CampoSeletorCliente
          value={value?.id_cliente ?? ''}
          onChange={v => onChange({ ...value, id_cliente: v })}
          label="Cliente"
          error={fieldErrors?.id_cliente}
        />
      </GridItem>

      <GridItem sm={12} md={6}>
        <CampoSeletorProduto
          value={value?.id_produto ?? ''}
          onChange={(v, product_value) =>
            onChange({
              ...value,
              id_produto: v,
              valor_total: +product_value * value.quantidade,
            })
          }
          label="Produto"
          error={fieldErrors?.id_produto}
        />
      </GridItem>

      <GridItem sm={12} md={3}>
        <CampoNumero
          value={value?.quantidade ?? ''}
          onChange={v => onChange({ ...value, quantidade: v })}
          label="Quantidade"
          error={fieldErrors?.quantidade}
        />
      </GridItem>

      <GridItem sm={12} md={9}>
        <CampoDinheiro
          value={value?.valor_total ?? ''}
          onChange={v => onChange({ ...value, valor_total: v })}
          label="Valor total"
          error={fieldErrors?.valor_total}
        />
      </GridItem>

      <GridItem sm={12} md={4}>
        <CampoSeletorUsuario
          value={value?.id_usuario_vendedor ?? ''}
          onChange={v => onChange({ ...value, id_usuario_vendedor: v })}
          label="Vendedor"
          error={fieldErrors?.id_usuario_vendedor}
        />
      </GridItem>

      <GridItem sm={12} md={4}>
        <CampoData
          label='Data da venda'
          type="datetime-local"
          value={value?.data_venda ? format(new Date(value.data_venda), "yyyy-MM-dd HH:mm:ss") : undefined}
          onChange={v => onChange({ ...value, data_venda: new Date(v).toISOString() })}
          error={fieldErrors?.data_venda}
        />
      </GridItem>
      <GridItem sm={12} md={4}>
        <CampoSeletorEstadoVenda
          value={value?.status ?? ''}
          onChange={v => onChange({ ...value, status: v })}
          label="Estado da venda"
          error={fieldErrors?.status}
        />
      </GridItem>

      {qCustomize.data?.length > 0 && (
        <GridItem sm={12}>
          <div style={{ marginTop: '12px' }}>Campos personalizados</div>
          <Grid>
            {qCustomize.data.map((it: any, idx: number) => (
              <GridItem
                sm={12}
                md={
                  qCustomize.data?.length - 1 === idx && idx % 2 === 0 ? 12 : 6
                }
              >
                <CampoPersonalizacao
                  label={it.rotulo}
                  type={it.tipo}
                  value={
                    value?.metadados?.[it.nome] != null
                      ? value?.metadados?.[it.nome]
                      : it.valor_padrao ?? ''
                  }
                  onChange={(v: any) => {
                    onChange({
                      ...value,
                      metadados: {
                        ...value.metadados,
                        [it.nome]: v,
                      },
                    });
                  }}
                  options={it.opcoes?.map((it: any) => ({ id: it, label: it }))}
                  error={fieldErrors?.[`metadados.${it.nome}`]}
                />
              </GridItem>
            ))}
          </Grid>
        </GridItem>
      )}

      <GridItem sm={12}>
        <CampoMemo
          value={value?.obs ?? ''}
          onChange={v => onChange({ ...value, obs: v })}
          label="Observações"
          error={fieldErrors?.obs}
        />
      </GridItem>
    </Grid>
  );
};
