import { NodeType } from "../../../types/NodeType";

const instance: NodeType = {
  type: "isFirstTime",
  label: "Primeira vez?",
  description:
    "Verifica se é a primeira vez que o usuário interage com o chat",
  category: "Chat",
  inputs(inputValues) {
    const result = [
      { type: "route", name: "route", label: "Entr. fluxo" },
    ];

    return result;
  },
  outputs(/* inputValues, context */) {
    return [
      { type: "route", name: "route-true", label: "Sim" },
      { type: "route", name: "route-false", label: "Não" },
    ];

  },
  async resolveOutputs(inputs, outConnections, context, localVars) {
    return null;
  },
  transcript(nodeId, inputValues, indent, nextNodeId, stack) {
    stack.push({
      result: `[Primeira vez?]`,
      indent,
    });

    const nextTrue = nextNodeId(nodeId, "route-true");
    if (nextTrue) {
      stack.push({ result: "(Se sim)", indent });
      stack.push({ id: nextTrue, indent: indent + 1 });
    }

    const nextFalse = nextNodeId(nodeId, "route-true");
    if (nextFalse) {
      stack.push({ result: "(Se não)", indent });
      stack.push({ id: nextFalse, indent: indent + 1 });
    }
  },
};

export default instance;
