import { NodeType } from "../../../types/NodeType";

const instance: NodeType = {
  type: "activityNPS",
  label: "Obter pontuação (NPS)",
  description: "Coleta uma pontuação para o atendimento",
  category: "Chat",
  inputs(inputValues) {
    const result = [
      { type: "route", name: "route", label: "Entr. fluxo" },
      { type: "string", name: "mensagem", label: "Mensagem" },
    ];

    const ids: string[] = [];
    if (inputValues?.mensagem) {
      const template = inputValues.mensagem;
      const re = /\{\{([^\}]+?)\}\}/g;

      let res: RegExpExecArray | null;
      while ((res = re.exec(template)) !== null) {
        if (!ids.includes(res[1])) ids.push(res[1]);
      }
      if (ids.length)
        result.push(
          ...ids.map((id) => ({ type: "string", name: id, label: id }))
        );
    }

    result.push({ type: "indexedList", name: "opcoes", label: "Pontuações" });

    result.push({ type: "boolean", name: "ocultar", label: "Ocultar resposta ao atendente" });

    return result;
  },
  outputs(inputValues, inputConnections) {
    console.log("inputValues", inputValues);

    const result = [
      { type: "string", name: "resposta", label: "Resposta" },
      { type: "number", name: "score", label: "Pontuação" },
    ];

    result.push({
      type: "route",
      name: "route-any",
      label: "Saída fluxo",
    });

    result.push({
      type: "route",
      name: "route",
      label: "Nenhuma das anteriores",
    });
    result.push({
      type: "route",
      name: "route-limit",
      label: "Lim. tent. excedido",
    });

    return result;
  },
  async resolveOutputs(inputs, outConnections, context, localVars) {
    return localVars.resposta ? { resposta: localVars.resposta } : null;
  },
  transcript: (nodeId, inputValues, indent, nextNodeId, stack) => {
    stack.push({ result: `[Obter pontuação] "${inputValues.mensagem}"`, indent });

    const nextOutro = nextNodeId(nodeId, "route");
    if (nextOutro) {
      stack.push({ result: `(SE nenhuma das anteriores)`, indent: indent + 1 });
      stack.push({ id: nextOutro, indent: indent + 1 });
    }

    const nextLimit = nextNodeId(nodeId, "route-limit");
    if (nextLimit) {
      stack.push({
        result: `(SE limite de tentativas excedido)`,
        indent: indent + 1,
      });
      stack.push({ id: nextLimit, indent: indent + 1 });
    }

    stack.push({ result: `(Fim SE)`, indent });

    const next = nextNodeId(nodeId, "route-any");
    if (next) {
      stack.push({ id: nextOutro, indent: indent });
    }
  },
};

export default instance;
