import React from "react";
import portProps from '@tera/shared/src/fluxoConfig/ports/document'
import axios from "axios";
import { toast } from "react-toastify";

type Props = {
  value: {
    filename: string
    size: number
    url: string
    mimetype: string
  } | null
  onChange: (value: {
    filename: string
    size: number
    url: string
    mimetype: string
  }) => void
}

const Port = ({ value, onChange }: Props) => {
  const [loading, setLoading] = React.useState(false)

  const uploadFile = React.useCallback(() => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".doc, .docx, .pdf, .xls, .xlsx, .ppt, .pptx";
    input.onchange = (e) => {
      const file = (e?.target as HTMLInputElement)?.files?.[0];
      const reader = new FileReader();
      reader.onload = () => {
        // upload to server
        const formData = new FormData();
            formData.append('file', file as Blob);

            axios.post('/api/assets/dialogo', formData)
            .then((response) => {
              onChange({
                // @ts-ignore
                filename: file?.name ?? '',
                size: file?.size ?? 0,
                url: reader.result as string,
                mimetype: file?.type ?? '',
              })
            }).catch((error) => {
              console.error('error', error)
              toast.error('Erro ao enviar documento')
            });
      }
      reader.readAsDataURL(file as Blob);
    }
    input.click();
    input.remove();
  }, [])

  return (
    <div>
      <button style={{ width: '100%' }} disabled={loading} onClick={() => uploadFile()}>Upload</button>
      <div>{value?.filename}</div>
      <div>{value?.mimetype}</div>
    </div>
  )
}

const port = {
  ...portProps,
  render({ value, onChange }: Props) {
    return <Port value={value} onChange={onChange} />

  }
}

export default port