import portProps from '@tera/shared/src/fluxoConfig/ports/list';

type Props = {
  value: string[] | null;
  onChange: (value: string[]) => void;
};

const Port = ({ value, onChange }: Props) => {
  return (
    <div>
      <div style={{ paddingLeft: '25px' }}>
        <button onClick={() => onChange([...(value ?? []), ''])}>
          + Adicionar
        </button>
      </div>
      {value?.map((item, index) => (
        <div key={index} style={{ display: 'flex', width: '100%' }}>
          <div style={{ display: 'flex', width: '100%', flexDirection: 'row' }}>
            <div style={{ minWidth: '25px' }}>
              {index + 1}:
              </div>
              <div style={{ flex: 1}}>
              <input
              style={{ width: '100%'}}
                type="text"
                value={item}
                onChange={e => {
                  const newValue = [...value];
                  newValue[index] = e.target.value;
                  onChange(newValue);
                }}
              />
              </div>
              <div style={{ minWidth: '25px' }}>
              <button
                style={{ width: '100%', textAlign: 'center' }}
                onClick={() => {
                  const newValue = [...value];
                  newValue.splice(index, 1);
                  onChange(newValue);
                }}
              >
                {' '}
                -{' '}
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const port = {
  ...portProps,
  render({ value, onChange }: Props) {
    return <Port value={value} onChange={onChange} />;
  },
};

export default port;
