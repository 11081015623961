import activitySendMessage from "./activitySendMessage";
import activitySendOptions from "./activitySendOptions";
import activitySendNotification from "./activitySendNotification";
import activitySendImage from "./activitySendImage";
import activitySendDocument from "./activitySendDocument";

import activityTransferToGroup from "./activityTransferToGroup";
import activityTransferToUser from "./activityTransferToUser";

import activityWhisper from "./activityWhisper";

import activitySendEmail from "./activitySendEmail";

import addTag from "./addTag";

import params from "./params";
import isFirstTime from "./isFirstTime";

export default {
  activitySendMessage,
  activitySendOptions,
  activitySendNotification,
  activitySendImage,
  activitySendDocument,
  activityTransferToGroup,
  activityTransferToUser,
  activityWhisper,
  activitySendEmail,
  addTag,
  params,
  isFirstTime,
};
