import { updateChatList } from './chatList';

export const chatDispatchAction = {
  CONNECT: 'CONNECT',
  DISCONNECT: 'DISCONNECT',
  UPDATE_CHATS: 'UPDATE_CHATS',
  UPDATE_FILA: 'UPDATE_FILA',
  SET_FILA: 'SET_FILA',
  SET_SELECTED_CHAT_ID: 'SET_SELECTED_CHAT_ID',
  SET_CHATS: 'SET_CHATS',
  SET_CHATS_LIST_CURSOR: 'SET_CHATS_LIST_CURSOR',
  UPSERT_CHAT: 'UPSERT_CHAT',
  REMOVE_CHAT: 'REMOVE_CHAT',
  SET_CHATS_CURSOR: 'SET_CHATS_CURSOR',
  SET_HAS_MORE_CHATS: 'SET_HAS_MORE_CHATS',
  SET_HAS_MORE_PAGES: 'SET_HAS_MORE_PAGES',
  SET_LOADING_CHATS: 'SET_LOADING_CHATS',
  ADD_FILA: 'ADD_FILA',
  REMOVE_FILA: 'REMOVE_FILA',
  SET_SENDING_MESSAGE: 'SET_SENDING_MESSAGE',
  SET_CHAT_MESSAGES: 'SET_CHAT_MESSAGES',
  ADD_CHAT_MESSAGE: 'ADD_CHAT_MESSAGE',
  ADD_CHAT_MESSAGES: 'ADD_CHAT_MESSAGES',
  UPDATE_CHAT_MESSAGE: 'UPDATE_CHAT_MESSAGE',
  SET_SERVER_ERROR: 'SET_SERVER_ERROR',
  LOADING_MESSAGES: 'LOADING_MESSAGES',
};

export type ActionType = keyof typeof chatDispatchAction;

export type Action = {
  type: ActionType;
  payload?: any;
};

export const chatReducer = (state: any, action: Action) => {
  console.log('reducer called', action, state);

  switch (action.type) {
    case chatDispatchAction.CONNECT:
      return { ...state, isConnected: true };
    case chatDispatchAction.DISCONNECT:
      return { ...state, isConnected: false };
    case chatDispatchAction.SET_SELECTED_CHAT_ID:
      return {
        ...state,
        selectedChatId: action.payload?.chat_id,
        selectedChatClientId: action.payload?.canal_cliente_id,
      };
    case chatDispatchAction.SET_CHATS:
      return { ...state, chats: action.payload };
    case chatDispatchAction.SET_CHATS_LIST_CURSOR: {
      const list = action.payload.prevCursor
        ? [...state.chats, ...action.payload.items]
        : action.payload.items;
      console.log('SET_CHATS_LIST_CURSOR.list', list);
      return { ...state, chats: list };
    }
    case chatDispatchAction.UPSERT_CHAT: {
      const chatIdx = state.chats?.findIndex(
        (chat: any) =>
          chat.canal_cliente_id == action.payload.chat.canal_cliente_id,
      );
      if (chatIdx > -1) {
        return {
          ...state,
          chats: updateChatList(
            state.chats,
            chatIdx,
            (chat: any) => ({
              ...chat,
              ...action.payload.chat,
            }),
            action.payload.move,
          ),
        };
      } else {
        return {
          ...state,
          chats: updateChatList(
            [action.payload.chat, ...(state.chats ?? [])],
            0,
            (chat: any) => chat,
            action.payload.move,
          ),
        };
      }
    }
    case chatDispatchAction.REMOVE_CHAT:
      return {
        ...state,
        chats: state.chats.filter((chat: any) => chat.id != action.payload.id),
        selectedChatId:
          state.selectedChatId == action.payload.id
            ? null
            : state.selectedChatId,
        selectedChatClientId:
          state.selectedChatClientId == action.payload.canal_cliente_id
            ? null
            : state.selectedChatClientId,
      };
    case chatDispatchAction.SET_CHATS_CURSOR:
      return { ...state, chatsCursor: action.payload };
    case chatDispatchAction.SET_HAS_MORE_CHATS:
      return { ...state, hasMoreChats: action.payload };
    case chatDispatchAction.SET_LOADING_CHATS:
      return { ...state, loadingChats: action.payload };
    case chatDispatchAction.UPDATE_CHATS:
      return { ...state, chats: action.payload };
    case chatDispatchAction.ADD_FILA:
      return {
        ...state,
        fila: [...state.fila, { id: action.payload.chat_id, fila_data_criacao: action.payload.fila_data_criacao }],
      };
    case chatDispatchAction.REMOVE_FILA:
      return {
        ...state,
        fila: state.fila.filter(
          (chat: any) => chat.id != action.payload.chat_id,
        ),
      };
    case chatDispatchAction.SET_FILA:
      return { ...state, fila: action.payload };
    case chatDispatchAction.SET_HAS_MORE_PAGES:
      return { ...state, hasMorePages: action.payload };
    case chatDispatchAction.SET_SENDING_MESSAGE:
      return { ...state, sendingMessage: action.payload };
    case chatDispatchAction.SET_CHAT_MESSAGES:
      return { ...state, selectedChatMessages: action.payload };
    case chatDispatchAction.ADD_CHAT_MESSAGE:
      if (action.payload.chat.canal_cliente_id != state.selectedChatClientId)
        return state;
      return {
        ...state,
        selectedChatMessages: [
          action.payload,
          ...(state.selectedChatMessages ?? []),
        ],
      };
    case chatDispatchAction.ADD_CHAT_MESSAGES:
      if (
        !action.payload?.length ||
        action.payload[0].canal_cliente_id != state.selectedChatClientId
      )
        return state;
      return {
        ...state,
        selectedChatMessages: [
          ...(state.selectedChatMessages ?? []),
          ...action.payload,
        ],
      };
    case chatDispatchAction.UPDATE_CHAT_MESSAGE: {
      if (!state.selectedChatMessages) return state;

      //TODO Performance: usar um Map para evitar o findIndex (índice -> canalId:canalClienteId ?)
      const selectedChat = state.chats?.find(
        (chat: any) => chat.canal_cliente_id == state.selectedChatClientId,
      );
      if (!selectedChat || selectedChat.canal_id != action.payload.canal_id)
        return state;

      if (!action.payload.mensagem) return state;

      const msgIdx = state.selectedChatMessages?.findIndex(
        (msg: any) => msg.id == action.payload.mensagem.id,
      );
      if (msgIdx === -1) return state;

      const newList = [
        ...state.selectedChatMessages.slice(0, msgIdx),
        {
          ...state.selectedChatMessages[msgIdx],
          ...action.payload.mensagem,
        },
        ...state.selectedChatMessages.slice(msgIdx + 1),
      ];

      return { ...state, selectedChatMessages: newList };
    }
    case chatDispatchAction.SET_SERVER_ERROR:
      return { ...state, serverError: action.payload };
    case chatDispatchAction.LOADING_MESSAGES:
      return { ...state, loadingMessages: action.payload };
    default:
      console.warn('action not handled', action);
  }
  return state;
};
