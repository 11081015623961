import { NodeType } from "../../../types/NodeType";

const instance: NodeType = {
  type: "activitySendOptions",
  label: "Enviar opções",
  description: "Envia uma mensagem com opções de resposta para o cliente",
  category: "Chat",
  inputs(inputValues) {
    const result = [
      { type: "route", name: "route", label: "Entr. fluxo" },
      { type: "string", name: "mensagem", label: "Mensagem" },
    ];

    if (inputValues?.mensagem) {
      const template = inputValues.mensagem;
      const re = /\{\{([^\}]+?)\}\}/g;

      const ids: string[] = [];
      let res: RegExpExecArray | null;
      while ((res = re.exec(template)) !== null) {
        if (!ids.includes(res[1])) ids.push(res[1]);
      }
      if (ids.length)
        result.push(
          ...ids.map((id) => ({ type: "string", name: id, label: id }))
        );
    }

    result.push({ type: "list", name: "opcoes", label: "Opções" });

    result.push({ type: "boolean", name: "ocultar", label: "Ocultar resposta ao atendente" });

    return result;
  },
  outputs(inputValues, inputConnections) {
    console.log("inputValues", inputValues);

    const result = [
      { type: "string", name: "resposta", label: "Resposta" },
      { type: "number", name: "index", label: "Índice" },
    ];

    if (inputValues?.opcoes?.length) {
      for (let i = 0; i < inputValues.opcoes.length; i++) {
        const opcao = inputValues.opcoes[i];
        result.push({
          type: "route",
          name: `route-${i + 1}`,
          label: opcao == "" ? `(Opção ${i + 1})` : opcao,
        });
      }
    }

    console.log("inputConnections", inputConnections);

    if (inputConnections?.find((it: any) => it.name === "opcoes") != null) {
      result.push({
        type: "route",
        name: "route-any",
        label: "Opção escolhida",
      });
    }

    result.push({
      type: "route",
      name: "route",
      label: "Nenhuma das anteriores",
    });
    result.push({
      type: "route",
      name: "route-limit",
      label: "Lim. tent. excedido",
    });

    return result;
  },
  async resolveOutputs(inputs, outConnections, context, localVars) {
    return localVars.resposta ? { resposta: localVars.resposta } : null;
  },
  transcript: (nodeId, inputValues, indent, nextNodeId, stack) => {
    stack.push({ result: `[Enviar opções] "${inputValues.mensagem}"`, indent });
    for (let i = 0; i < inputValues.opcoes.length; i++) {
      const opcao = inputValues.opcoes[i];

      stack.push({ result: `(Se ${opcao})`, indent });
      stack.push({
        id: nextNodeId(nodeId, `route-${i + 1}`),
        indent: indent + 1,
      });
    }

    const nextOutro = nextNodeId(nodeId, "route");
    if (nextOutro) {
      stack.push({ result: `(SE nenhuma das anteriores)`, indent: indent + 1 });
      stack.push({ id: nextOutro, indent: indent + 1 });
    }

    const nextLimit = nextNodeId(nodeId, "route-limit");
    if (nextLimit) {
      stack.push({
        result: `(SE limite de tentativas excedido)`,
        indent: indent + 1,
      });
      stack.push({ id: nextLimit, indent: indent + 1 });
    }

    stack.push({ result: `(Fim SE)`, indent });
  },
};

export default instance;
