import React from "react";
import portProps from '@tera/shared/src/fluxoConfig/ports/image'
import axios from "axios";
import { toast } from "react-toastify";

type Props = {
  value: {
    filename: string
    size: number
    url: string
    mimetype: string
  } | null
  onChange: (value: {
    filename: string
    size: number
    url: string
    mimetype: string
  }) => void
}

const Port = ({ value, onChange }: Props) => {
  return (
    <div>
      <button style={{ width: '100%' }} onClick={() => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = ".jpg, .jpeg, .png";
        input.onchange = (e) => {
          const file: Blob | File | undefined = (e?.target as HTMLInputElement)?.files?.[0];
          const reader = new FileReader();
          reader.onload = () => {
            const formData = new FormData();
            formData.append('file', file as Blob);

            axios.post('/api/assets/dialogo', formData)
            .then((response) => {
              onChange({
                // @ts-ignore
                filename: file?.name ?? '',
                size: file?.size ?? 0,
                url: reader.result as string,
                mimetype: file?.type ?? '',
              })
            }).catch((error) => {
              console.error('error', error)
              toast.error('Erro ao enviar imagem')
            });
          }
          reader.readAsDataURL(file as Blob);
        }
        input.click();
        input.remove();
      }}>Upload</button>
      <div>{value?.filename}</div>
      <img src={value?.url} style={{ width: '100%' }} />
    </div>
  )
}

const port = {
  ...portProps,
  render({ value, onChange }: Props) {
    return <Port value={value} onChange={onChange} />
  }
}

export default port