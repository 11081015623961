import { Sector, Cell, PieChart, Pie } from 'recharts';

type Props = {
    value: number;
}
const GaugeChart = ({ value }: Props) => {
    const width = 300;
    const chartValue = value;
    const colorData = [{
        value: 25,
        color: 'var(--tc-color-danger)'
    }, {
        value: 25,
        color: 'var(--tc-color-warning)' // '#663399'
    }, {
        value: 25,
        color: 'var(--tc-color-info)'
    }, {
        value: 25,
        color: 'var(--tc-color-success)'
    }
    ];

    const activeSectorIndex = colorData.map((cur, index, arr) => {
        const curMax = [...arr]
            .splice(0, index + 1)
            .reduce((a, b) => ({ value: a.value + b.value }))
            .value;
        return (chartValue > (curMax - cur.value)) && (chartValue <= curMax);
    })
        .findIndex(cur => cur);

    const sumValues = colorData
        .map(cur => cur.value)
        .reduce((a, b) => a + b);

    const arrowData = [
        { value: chartValue },
        { value: 0 },
        { value: (sumValues - chartValue) || 0.1 }
    ];

    const pieProps = {
        startAngle: 180,
        endAngle: 0,
        cx: width / 2,
        cy: width / 2
    };

    const pieRadius = {
        innerRadius: (width / 2) * 0.35,
        outerRadius: (width / 2) * 0.4
    };

    const Arrow = ({ cx, cy, midAngle, outerRadius }) => { //eslint-disable-line react/no-multi-comp
        const RADIAN = Math.PI / 180;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const mx = cx + (outerRadius + width * 0.03) * cos;
        const my = cy + (outerRadius + width * 0.03) * sin;
        return (
            <g>
                <circle cx={cx} cy={cy} r={width * 0.02} fill="#444" stroke="none" />
                <path d={`M${cx},${cy}L${mx},${my}`} strokeWidth="6" stroke="#444" fill="none" strokeLinecap="round" />
            </g>
        );
    };

    const ActiveSectorMark = ({ cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill }) => { //eslint-disable-line react/no-multi-comp
        return (
            <g>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius * 1.2}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
            </g>
        );
    };

    return (
        <PieChart width={width} height={(width / 2) + 30}>
            <Pie
                activeIndex={activeSectorIndex}
                activeShape={ActiveSectorMark}
                data={colorData}
                fill="#8884d8"
                {...pieRadius}
                {...pieProps}
                isAnimationActive={false}
            >
                {
                    colorData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colorData[index].color} />
                    ))
                }
            </Pie>
            <Pie
                stroke="none"
                activeIndex={1}
                activeShape={Arrow}
                data={arrowData}
                outerRadius={pieRadius.innerRadius}
                fill="none"
                {...pieProps}
                isAnimationActive={false}
            />
        </PieChart>
    );
};

export default GaugeChart;