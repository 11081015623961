import start from "./start";
import end from "./end";
import loop from "./loop";
import api from "./api";
import apiCallV2 from "./apiCallV2";

import chatNodes from "./chat/_index";
import booleanNodes from "./boolean/_index";
import timestampNodes from "./timestamp/_index";
import datetimeNodes from "./datetime/_index";
import stringNodes from "./string/_index";
import numberNodes from "./number/_index";
import listNodes from "./list/_index";
import objectNodes from "./object/_index";
import tomticketNodes from "./tomticket/_index";
import nps from "./nps/_index";

import counter from "./counter";

import domainNodes from "./domain/_index";
import aiNodes from "./ai/_index";

import newFlow from "./newFlow";
import delay from "./delay/_index";

export default {
  ...chatNodes,
  ...booleanNodes,
  ...timestampNodes,
  ...datetimeNodes,
  ...stringNodes,
  ...numberNodes,
  ...listNodes,
  ...objectNodes,
  ...tomticketNodes,
  ...domainNodes,
  ...aiNodes,
  ...delay,
  ...nps,
  api,
  apiCallV2,
  start,
  end,
  loop,
  newFlow,
  counter,
};
