import CampoNumero from '@/components/Formulario/CampoNumero';
import Grid, { GridItem } from '@/components/layout/Grid';
import Modal from '@/components/layout/Modal';
import VBox from '@/components/layout/VBox';
import axios from 'axios';
import React from 'react';
import { useMutation, useQuery } from 'react-query';
import styles from './ModalImportarArquivo.module.css';
import CampoSeletor from '@/components/Formulario/CampoSeletor';
import Botao from '@/components/Botao';
import { Accordion } from '@/components/layout/Accordion';
import AlertBox from '@/components/layout/AlertBox';

type Registro = {
  cliente: {
    nome: string;
  };
  produto: {
    nome: string;
  };
  vendedor: {
    nome: string;
  };
};

type RegistroErro = Registro & {
  erro: string;
};

type ImportacaoResult = {
  sucesso: Registro[];
  erro: RegistroErro[];
  produto_em_branco: Registro[];
  vendedor_nao_encontrado: Registro[];
  cliente_em_banco: Registro[];
  venda_existente: Registro[];
};

type Props = {
  show: boolean;
  onConfirm: () => void;
  onClose: () => void;
};
export const ModalImportarArquivo = ({ show, onClose, onConfirm }: Props) => {
  const [importResults, setImportResults] =
    React.useState<ImportacaoResult | null>(null);
  const [importColumns, setImportColumns] = React.useState<any>(null);
  const [importAssocs, setImportAssocs] = React.useState<any>(null);

  const [file, setFile] = React.useState<File | null>(null);
  const [ddd, setDdd] = React.useState<string | null>(null);
  const formRef = React.useRef<HTMLFormElement>(null);
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  // const qCustomizeCliente = useQuery('cliente/meta', async () => {
  //   const res = await axios.get('/api/cliente/meta', {
  //     withCredentials: true,
  //   });

  //   return res.data.result;
  // });

  // const qCustomizeProduto = useQuery('produto/meta', async () => {
  //   const res = await axios.get('/api/produto/meta', {
  //     withCredentials: true,
  //   });

  //   return res.data.result;
  // });

  const qCustomizeVenda = useQuery('venda/meta', async () => {
    const res = await axios.get('/api/venda/meta', {
      withCredentials: true,
    });

    return res.data.result;
  });

  const importColumnsOptions = React.useMemo(() => {
    if (!importColumns) return [];
    return importColumns.map((c: any, idx: number) => ({
      id: c,
      label: c,
    }));
  }, [importColumns]);

  const importMutator = useMutation(
    async (data: any) => {
      if (!data.file) {
        alert('Nenhum arquivo selecionado.');
        return;
      }

      setImportResults(null);

      const formData = new FormData();
      formData.append('ddd', data.ddd!);
      formData.append('file', data.file as Blob);
      formData.append('columns', JSON.stringify(data.importAssocs));

      const extension = data.file!.name.split('.').pop();
      if (!['csv'].includes(extension!)) {
        alert('Formato de arquivo inválido.');
        return;
      }

      const response = await axios.post(
        `/api/venda/upload/${extension?.toLowerCase()}`,
        formData,
      );
      return response.data;
    },
    {
      onSuccess: data => {
        setImportResults(data.resultado as ImportacaoResult);

        onConfirm();
      },
      onError: error => {
        console.error(error);
      },
      onSettled: () => {
        fileInputRef.current!.value = '';
        setFile(null);
      },
    },
  );

  const columnsMutator = useMutation(
    async (fileData: File) => {
      if (!fileData) {
        alert('Nenhum arquivo selecionado.');
        return;
      }

      setImportColumns(null);

      const formData = new FormData();
      formData.append('file', fileData as Blob);

      const extension = fileData!.name.split('.').pop();
      if (!['csv'].includes(extension!)) {
        alert('Formato de arquivo inválido.');
        return;
      }

      try {
        const result = await axios.post(
          `/api/venda/upload/csv/columns`,
          formData,
        );
        return result.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    {
      onSuccess: data => {
        setImportColumns(data.columns);
      },
    },
  );

  const handleImportStart = React.useCallback(async () => {
    importMutator.mutate({ file, ddd, importAssocs });
  }, [file, ddd, importAssocs]);

  const handleFileChange = (e: any) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    columnsMutator.mutate(selectedFile);
  };

  React.useEffect(() => {
    if (show) importMutator.reset();
  }, [show]);

  return (
    <Modal
      show={show}
      title="Importar contatos"
      onClose={() => {
        setImportResults(null);
        onClose();
      }}
      hideControls={true}
    >
      <VBox>
        <form
          ref={formRef}
          onSubmit={e => {
            e.preventDefault();
            handleImportStart();
          }}
        >
          <Grid>
            <GridItem sm={12} md={3}>
              <CampoNumero
                label="DDD"
                value={ddd ?? ''}
                onChange={v => setDdd(v)}
                disabled={importMutator.isLoading}
                error={!ddd ? 'Campo necessário' : undefined}
              />
            </GridItem>
            <GridItem sm={12} md={9}>
              <label>Arquivo</label>
              <input
                ref={fileInputRef}
                type="file"
                accept=".csv"
                className={styles.fileInput}
                onChange={handleFileChange}
                disabled={importMutator.isLoading}
                style={{
                  color: fileInputRef.current?.value
                    ? 'green'
                    : 'var(--tc-color-danger)',
                }}
              />
            </GridItem>
          </Grid>

          {columnsMutator.isSuccess && importColumns?.length > 0 && (
            <VBox>
              <p>
                Arquivo: {file ? <b>{file.name}</b> : '(Nenhum selecionado)'}
              </p>
              <p>
                Associe as colunas do arquivo com seus correspondentes do
                sistema:
              </p>
              <Grid>
                <GridItem sm={6}>Nome cliente</GridItem>
                <GridItem sm={6}>
                  <CampoSeletor
                    value={importAssocs?.cliente_nome}
                    onChange={v =>
                      setImportAssocs({ ...importAssocs, cliente_nome: v })
                    }
                    options={importColumnsOptions}
                  />
                </GridItem>
              </Grid>
              <Grid>
                <GridItem sm={6}>Telefone cliente</GridItem>
                <GridItem sm={6}>
                  <CampoSeletor
                    value={importAssocs?.cliente_telefone}
                    onChange={v =>
                      setImportAssocs({
                        ...importAssocs,
                        cliente_telefone: v,
                      })
                    }
                    options={importColumnsOptions}
                  />
                </GridItem>
              </Grid>

              <Grid>
                <GridItem sm={6}>E-mail cliente</GridItem>
                <GridItem sm={6}>
                  <CampoSeletor
                    value={importAssocs?.cliente_email}
                    onChange={v =>
                      setImportAssocs({ ...importAssocs, cliente_email: v })
                    }
                    options={importColumnsOptions}
                  />
                </GridItem>
              </Grid>

              <Grid>
                <GridItem sm={6}>Nome produto</GridItem>
                <GridItem sm={6}>
                  <CampoSeletor
                    value={importAssocs?.produto_nome}
                    onChange={v =>
                      setImportAssocs({ ...importAssocs, produto_nome: v })
                    }
                    options={importColumnsOptions}
                  />
                </GridItem>
              </Grid>

              <Grid>
                <GridItem sm={6}>Nome vendedor</GridItem>
                <GridItem sm={6}>
                  <CampoSeletor
                    value={importAssocs?.vendedor_nome}
                    onChange={v =>
                      setImportAssocs({ ...importAssocs, vendedor_nome: v })
                    }
                    options={importColumnsOptions}
                  />
                </GridItem>
              </Grid>

              {/* {qCustomizeCliente.isSuccess && qCustomizeCliente.data.length && (
                <Grid>
                  {qCustomizeCliente.data
                    ?.filter((it: any) => it.listavel)
                    ?.map((c: any, idx: number) => (
                      <React.Fragment key={idx}>
                        <GridItem sm={6}>{c.rotulo}</GridItem>
                        <GridItem sm={6}>
                          <CampoSeletor
                            value={
                              importAssocs?.[`cliente_metadados.${c.nome}`]
                            }
                            onChange={v =>
                              setImportAssocs({
                                ...importAssocs,
                                [`cliente_metadados.${c.nome}`]: v,
                              })
                            }
                            options={importColumnsOptions}
                          />
                        </GridItem>
                      </React.Fragment>
                    ))}
                </Grid>
              )}

              {qCustomizeProduto.isSuccess && qCustomizeProduto.data.length && (
                <Grid>
                  {qCustomizeProduto.data
                    ?.filter((it: any) => it.listavel)
                    ?.map((c: any, idx: number) => (
                      <React.Fragment key={idx}>
                        <GridItem sm={6}>{c.rotulo}</GridItem>
                        <GridItem sm={6}>
                          <CampoSeletor
                            value={
                              importAssocs?.[`produto_metadados.${c.nome}`]
                            }
                            onChange={v =>
                              setImportAssocs({
                                ...importAssocs,
                                [`produto_metadados.${c.nome}`]: v,
                              })
                            }
                            options={importColumnsOptions}
                          />
                        </GridItem>
                      </React.Fragment>
                    ))}
                </Grid>
              )} */}

              {qCustomizeVenda.isSuccess && qCustomizeVenda.data.length && (
                <Grid>
                  {qCustomizeVenda.data
                    ?.filter((it: any) => it.listavel)
                    ?.map((c: any, idx: number) => (
                      <React.Fragment key={idx}>
                        <GridItem sm={6}>{c.rotulo}</GridItem>
                        <GridItem sm={6}>
                          <CampoSeletor
                            value={importAssocs?.[`venda_metadados.${c.nome}`]}
                            onChange={v =>
                              setImportAssocs({
                                ...importAssocs,
                                [`venda_metadados.${c.nome}`]: v,
                              })
                            }
                            options={importColumnsOptions}
                          />
                        </GridItem>
                      </React.Fragment>
                    ))}
                </Grid>
              )}
            </VBox>
          )}

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Botao
              disabled={
                importMutator.isLoading ||
                columnsMutator.isLoading ||
                !importColumns?.length
              }
              type="submit"
            >
              Iniciar importação
            </Botao>
          </div>
        </form>

        {!importMutator.isLoading && importResults && (
          <VBox>
            <p>
              O arquivo foi processado com sucesso. Segue abaixo o resultado:
            </p>

            <Accordion
              variant={
                importResults.sucesso.length > 0 ? 'success' : 'secondary'
              }
              title={<span>Sucesso: {importResults.sucesso.length}</span>}
            >
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>Cliente</th>
                    <th>Produto</th>
                    <th>Vendedor</th>
                  </tr>
                </thead>
                <tbody>
                  {importResults.sucesso.map((r, idx) => (
                    <tr key={idx}>
                      <td>{r.cliente.nome}</td>
                      <td>{r.produto.nome}</td>
                      <td>{r.vendedor.nome}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Accordion>

            <Accordion
              variant={
                importResults.produto_em_branco.length > 0
                  ? 'info'
                  : 'secondary'
              }
              title={
                <span>
                  Produto em branco: {importResults.produto_em_branco.length}
                </span>
              }
            >
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>Cliente</th>
                    <th>Produto</th>
                    <th>Vendedor</th>
                  </tr>
                </thead>
                <tbody>
                  {importResults.produto_em_branco.map((r, idx) => (
                    <tr key={idx}>
                      <td>{r.cliente.nome}</td>
                      <td>{r.produto.nome}</td>
                      <td>{r.vendedor.nome}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Accordion>

            <Accordion
              variant={
                importResults.vendedor_nao_encontrado.length > 0
                  ? 'info'
                  : 'secondary'
              }
              title={
                <span>
                  Vendedor não encontrado:{' '}
                  {importResults.vendedor_nao_encontrado.length}
                </span>
              }
            >
              <AlertBox variant="info">
                Você pode cadastrar estes produtos e importar novamente. As
                vendas já cadastradas serão mostradas como já existentes.
              </AlertBox>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>Cliente</th>
                    <th>Produto</th>
                    <th>Vendedor</th>
                  </tr>
                </thead>
                <tbody>
                  {importResults.vendedor_nao_encontrado.map((r, idx) => (
                    <tr key={idx}>
                      <td>{r.cliente.nome}</td>
                      <td>{r.produto.nome}</td>
                      <td>{r.vendedor.nome}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Accordion>

            <Accordion
              variant={
                importResults.venda_existente.length > 0 ? 'info' : 'secondary'
              }
              title={
                <span>
                  Venda já existente: {importResults.venda_existente.length}
                </span>
              }
            >
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>Cliente</th>
                    <th>Produto</th>
                    <th>Vendedor</th>
                  </tr>
                </thead>
                <tbody>
                  {importResults.venda_existente.map((r, idx) => (
                    <tr key={idx}>
                      <td>{r.cliente.nome}</td>
                      <td>{r.produto.nome}</td>
                      <td>{r.vendedor.nome}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Accordion>

            <Accordion
              variant={importResults.erro.length > 0 ? 'warning' : 'secondary'}
              title={<span>Erro: {importResults.erro.length}</span>}
            >
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>Cliente</th>
                    <th>Produto</th>
                    <th>Vendedor</th>
                    <th>Erro</th>
                  </tr>
                </thead>
                <tbody>
                  {importResults.erro.map((r, idx) => (
                    <tr key={idx}>
                      <td>{r.cliente.nome}</td>
                      <td>{r.produto.nome}</td>
                      <td>{r.vendedor.nome}</td>
                      <td>{r.erro}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Accordion>
          </VBox>
        )}
      </VBox>
    </Modal>
  );
};
