import string from './string';
import number from './number';
import route from './route';
import stringOperator from './stringOperator';
import api from './api';
import apiCallV2 from './apiCallV2';
import datetime from './datetime';
import boolean from './boolean';
import list from './list';
import userOptions from './userOptions';
import object from './object';
import datetimeOperator from './datetimeOperator';
import msgProgramada from './msgProgramada';
import timestamp from './timestamp';
import image from './image';
import document from './document';
import portType from './portType';
import numberOperator from './numberOperator';
import objectType from './objectType';
import numberCalcOp from './numberCalcOp';
import domainPorts from './domain/_index';
import delay from './delay';
import indexedList from './indexedList';

const portRenderers = {
  ...domainPorts,
  api,
  apiCallV2,
  datetime,
  boolean,
  image,
  document,
  string,
  stringOperator,
  datetimeOperator,
  number,
  route,
  list,
  object,
  userOptions,
  msgProgramada,
  timestamp,
  portType,
  objectType,
  numberOperator,
  numberCalcOp,
  delay,
  indexedList,
};

export default portRenderers;