import React, { CSSProperties } from 'react';
import styles from './CardCliente.module.css';
import Card from '../../components/layout/Card';
import HBox from '../../components/layout/HBox';
import Icon from '@mdi/react';
import { mdiAccountClock, mdiClock, mdiDrag, mdiHeadset } from '@mdi/js';
import Subtitulo from '../../components/tipografia/Subtitulo';
import Descricao from '../../components/tipografia/Descricao';
import { Draggable } from 'react-beautiful-dnd';
import VBox from '../../components/layout/VBox';
import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const grid = 8;
const getItemStyle = (
  isDragging: boolean,
  draggableStyle: any,
): CSSProperties => ({
  userSelect: 'none',
  padding: 0,
  margin: 0,

  background: isDragging
    ? 'var(--tc-color-success-200)'
    : 'var(--tc-color-white)',
  opacity: isDragging ? 0.5 : 1,
  minWidth: '200px',
  width: '200px',
  maxWidth: '250px',
  ...draggableStyle,
});

const statusDesc: Record<string, { icon: string; label: string }> = {
  aguardando_atendente: {
    icon: mdiAccountClock,
    label: 'Aguardando atend.',
  },
  atendimento: { icon: mdiHeadset, label: 'Em atendimento' },
  atualizando: { icon: mdiClock, label: 'Atualizando...' },
};

type CardClienteProps = {
  item: any;
  index: number;
  dragItem?: any;
};
const CardCliente = ({ item, index, dragItem }: CardClienteProps) => {
  return (
    <Draggable
      key={item.fila_id}
      draggableId={`${item.fila_id}-${item.id}`}
      index={index}
    >
      {(provided, snapshot) => (
        <Card
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            gap: '2px',
            ...getItemStyle(
              snapshot.isDragging || dragItem?.id == item.id,
              provided.draggableProps.style,
            ),
          }}
          title={`Fluxo de atendimento: ${item.dialogo_nome}\nCanal: ${item.canal_nome}`}
        >
          <HBox
            style={{
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Icon path={mdiDrag} size={1} color="var(--tc-color-gray-500)" />
            <VBox style={{ maxWidth: 'calc(100% - 30px)' }}>
              <HBox style={{ alignItems: 'center', justifyContent: 'center' }}>
                <Icon
                  path={statusDesc[item.estado].icon}
                  size={1}
                  color={
                    item.estado == 'aguardando_atendente'
                      ? 'var(--tc-color-warning)'
                      : 'var(--tc-color-gray-500)'
                  }
                />

                <Subtitulo
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {item.nome}
                </Subtitulo>
              </HBox>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '2px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  fontSize: '12px',
                }}
              >
                <div>Posição #{index + 1}</div>
                <div>
                  Entr: {item.fila_data_criacao
                    ? 'Faz ' + formatDistanceToNow(item.fila_data_criacao, { locale: ptBR })
                    : item.data_criacao
                      ? 'Faz ' + formatDistanceToNow(item.data_criacao, { locale: ptBR })
                      : 'N/A'
                  }
                </div>
              </div>
            </VBox>
          </HBox>
        </Card>
      )}
    </Draggable>
  );
};

export default CardCliente;
